<template>
  <div class="bulkImportPage">
    <div v-if="!flag.progressIng" class="facilityBox">
      <!-- 表格区域 -->
      <div class="tableWrap">
        <div style="
            display: flex;
            justify-content: space-between;
            align-content: center;
          ">
          <el-breadcrumb separator="/" style="margin: 0 0px 10px 0">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            <el-breadcrumb-item>导入网络货运司机信息</el-breadcrumb-item>
          </el-breadcrumb>

          <el-tooltip placement="left">
            <div slot="content">
              1.草稿箱可暂存信息，防止丢失<br />
              2.必填证照齐全的可以提交平台审核，不齐全的需在“编辑”页继续补齐；<br />
              3.“批量上传” 页最多100条信息；<br />
              4.受您电脑带宽影响，信息量越大，证照越多，上传时间越久；<br />
              5.对于暂存的信息，如需再次修改或补充，请先勾选要补充的信息，再次点击“批量司机上传”
            </div>
            <div style="margin: 0px 20px; color: #0f5fff; cursor: pointer">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>
        <div style="
            margin: 10px 0 10px 0;
            display: flex;
            justify-content: space-between;
          ">
          <el-form ref="form" :model="driverForm" label-width="90px" inline>
            <el-form-item label="司机姓名" prop="DriverName">
              <el-input v-model="driverForm.DriverName" placeholder="请输入司机姓名"></el-input>
            </el-form-item>
            <el-form-item label="司机手机号" prop="TelPhone">
              <el-input v-model="driverForm.TelPhone" placeholder="请输入司机手机号"></el-input>
            </el-form-item>
            <el-form-item label="平台运力池" prop="IsInTCCode">
              <el-select v-model="driverForm.IsInTCCode" placeholder="请选择平台运力池">
                <el-option v-for="item in IsInTCList" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="司机属性" prop="IdentityType">
              <el-select v-model="driverForm.IdentityType" placeholder="请选择司机属性">
                <el-option v-for="item in IdentityTypeList" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
              <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm()">清空</el-button>
            </el-form-item>
            <br />
            <el-button type="primary" size="medium" icon="el-icon-upload2" @click="batchClick()"
              style="margin-top: 2px">批量司机上传</el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete" :disabled="this.tableData.length == 0"
              @click="delClick()">删除选中</el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete" :disabled="this.batchData.length == 0"
              @click="delClickAll()" v-if="!getIsCarCaptain">一键删除所有</el-button>
            <el-button type="primary" size="medium" icon="el-icon-download" @click="downloadClick()">下载司机表格</el-button>
            <el-button type="primary" size="medium" @click="download" icon="el-icon-download">下载司机表格模板</el-button>
            <el-button size="medium" @click="$router.back()" icon="el-icon-arrow-left">返回</el-button>
          </el-form>
        </div>
        <el-table ref="multipleTable" :data="batchData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
          :height="tableHeight" @select="selectChange" @select-all="selectChange" :row-class-name="setDeletedRow"
          v-loading="batchDataLoading">
          <el-table-column fixed align="center" type="selection" width="55"></el-table-column>
          <el-table-column fixed align="center" type="index" label="序号" width="50"></el-table-column>
          <el-table-column fixed align="center" prop="DriverName" label="司机姓名" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TelPhone" label="司机手机号码" width="160" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="VehicleCarNumber" label="车牌号" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="IdentityType" label="司机属性" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              {{
                scope.row.IdentityType == "1"
                ? "司机"
                : scope.row.IdentityType == "2"
                  ? "代收人"
                  : "未知"
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="BankCardNo" label="银行卡号" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="RTQCertificate" label="从业资格证" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverLicesenAuthority" label="驾驶证发证机关" width="180"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="" label="平台运力池" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag :type="scope.row.IsInTCName == '不存在' ? 'danger' : 'success'">
                {{ scope.row.IsInTCName }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverCardOnURL" label="身份证(正面)" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.DriverCardOnURL" style="width: 200; height: 50px" :src="scope.row.DriverCardOnURL"
                :preview-src-list="[scope.row.DriverCardOnURL]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverCardBackURL" label="身份证(反面)" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.DriverCardBackURL" style="width: 200; height: 50px"
                :src="scope.row.DriverCardBackURL" :preview-src-list="[scope.row.DriverCardBackURL]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverLicenseOnURL" label="驾驶证(正页)" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.DriverLicenseOnURL" style="width: 200; height: 50px"
                :src="scope.row.DriverLicenseOnURL" :preview-src-list="[scope.row.DriverLicenseOnURL]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverLicenseBackURL" label="驾驶证(副页)" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.DriverLicenseBackURL" style="width: 200; height: 50px"
                :src="scope.row.DriverLicenseBackURL" :preview-src-list="[scope.row.DriverLicenseBackURL]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="RTQCertificateURL" label="道路运输从业资格证" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.RTQCertificateURL" style="width: 200; height: 50px"
                :src="scope.row.RTQCertificateURL" :preview-src-list="[scope.row.RTQCertificateURL]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="BankCardURL" label="银行卡" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.BankCardURL" style="width: 200; height: 50px" :src="scope.row.BankCardURL"
                :preview-src-list="[scope.row.BankCardURL]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverIDCardPhoto" label="人员手持身份证" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.DriverIDCardPhoto" style="width: 200; height: 50px"
                :src="scope.row.DriverIDCardPhoto" :preview-src-list="[scope.row.DriverIDCardPhoto]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverPhotoURL" label="人车合影" width="200">
            <template slot-scope="scope">
              <el-image v-if="scope.row.DriverPhotoURL" style="width: 200; height: 50px" :src="scope.row.DriverPhotoURL"
                :preview-src-list="[scope.row.DriverPhotoURL]">
              </el-image>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="margin-top: 10px">
          <el-pagination background class="pagination" @current-change="handleCurrentChange" @size-change="sizeChange"
            :current-page.sync="pagination.page" :page-size="pagination.pagesize" :page-sizes="[10, 30, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
          </el-pagination>
        </div>
      </div>
      <!-- 导入司机信息dialog -->
      <el-dialog class="driverInfo" :visible.sync="flag.showDriverInfo" width="540px" top="35vh">
        <span slot="title" class="dialog-title"> 上传司机表格 </span>
        <div class="body">
          <el-alert type="warning" title="温馨提示：1.请按照excel字段规则填写;2.单次导入不超过500条数据;" :closable="false">
          </el-alert>
          <input ref="driverInfo" type="file" style="display: none" @change="importDriverInfo" />
        </div>
        <span slot="footer">
          <el-button type="primary" size="medium" @click="DriverExcel" icon="el-icon-upload2">上传司机表格</el-button>
        </span>
      </el-dialog>
      <!-- 导入司机证件照dialog -->
      <el-dialog class="driverPhoto" top="10vh" :visible.sync="flag.showDriverPhoto" width="60%"
        :close-on-click-modal="false" :close-on-press-escape="false" :show-close="!uploading">
        <span slot="title" class="dialog-title"> 上传司机证照 </span>
        <div class="body">
          <el-alert type="warning" :closable="false">
            <span slot="title">
              <span>温馨提示：</span><br />
              <span>1.限制一次性最多<span>上传100张</span></span><br />
              <span>2.支持图片格式：<span>jpeg/jpg/png</span></span><br />
              <span>3.司机证照命名规则如下：</span><br />
              <span>身份证照片（正面）：手机号-1.jpg、
                身份证照片（背面）：手机号-2.jpg、
                驾驶证照片（正页）：手机号-3.jpg、
                驾驶证照片（副页）：手机号-4.jpg</span><br />
              <span>从业资格证：手机号-5.jpg、 银行卡：手机号-6.jpg、
                手持身份证照：手机号-7.jpg、 人车合影：手机号-8.jpg</span><br />
              <span>如文件名与表格中不匹配将不显示在图片列表中，请仔细检查。</span><br />
            </span>
          </el-alert>
          <!-- 上传图片区域 -->
          <div class="uploadArea" v-if="!uploading">
            <div class="bulkImportList">
              <div class="item" v-for="(item, index) in bulkImportList" :key="index">
                <img :src="item.url" />
                <span>{{ item.name }}</span>
                <i class="el-icon-delete" @click="delListItem(index)"></i>
              </div>
            </div>
            <div class="upload" @click="$refs.driverPhoto.click()"></div>
          </div>
          <div v-else style="width: 100%; padding: 10px 0 0 0; text-align: center">
            <el-progress :width="160" type="circle" :stroke-width="10" :percentage="flag.riskProgress"></el-progress>
          </div>
          <input ref="driverPhoto" id="inp" type="file" style="display: none" @change="importDriverPhoto" multiple />
        </div>
        <span slot="footer">
          <el-button type="primary" size="medium" @click="confirmImportDriverPhoto" :loading="uploading"
            :disabled="bulkImportList.length == 0 ? true : false">
            {{ !uploading ? "上传司机证照" : "上传中......" }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 编辑司机图片dialog -->
      <el-dialog class="editDriverForm" :visible.sync="flag.editDriverPhoto" width="1200px" :close-on-click-modal="false"
        :close-on-press-escape="false">
        <span slot="title" class="dialog-title"> 图片修改 </span>
        <el-form :model="editDriverForm" :rules="editDriverRule" ref="editDriverForm" label-width="160px">
          <div class="content">
            <div class="upBox">
              <UploadImg ref="idFace" :loading="editDriverForm.idFace.loading" :uploaded="editDriverForm.idFace.uploaded"
                :picUrl="editDriverForm.idFace.picUrl" :isModel="false" @update:file="updateFile" :fileType="'Driver'"
                @change="uploadIdFace(editDriverForm.idFace, false)" @deleteImg="deleteImg(editDriverForm.idFace)">
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                身份证(正面)
                <i @click="imgCase(1)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="idBack" :loading="editDriverForm.idBack.loading" :uploaded="editDriverForm.idBack.uploaded"
                :picUrl="editDriverForm.idBack.picUrl" :isModel="false" @update:file="updateFile" :fileType="'Driver'"
                @change="uploadIdBack(editDriverForm.idBack, false)" @deleteImg="deleteImg(editDriverForm.idBack)">
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                身份证(反面)
                <i @click="imgCase(2)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="driverFace" :loading="editDriverForm.driverFace.loading"
                :uploaded="editDriverForm.driverFace.uploaded" :picUrl="editDriverForm.driverFace.picUrl" :isModel="false"
                @update:file="updateFile" :fileType="'Driver'"
                @change="uploadDriverFace(editDriverForm.driverFace, false)"
                @deleteImg="deleteImg(editDriverForm.driverFace)">
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                驾驶证(正页)
                <i @click="imgCase(9)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="driverBack" :loading="editDriverForm.driverBack.loading"
                :uploaded="editDriverForm.driverBack.uploaded" :picUrl="editDriverForm.driverBack.picUrl" :isModel="false"
                @update:file="updateFile" :fileType="'Driver'"
                @change="uploadDriverBack(editDriverForm.driverBack, false)"
                @deleteImg="deleteImg(editDriverForm.driverBack)">
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                驾驶证(副页)
                <i @click="imgCase(10)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="upBox">
              <UploadImg ref="RTQCertificateURL" :loading="editDriverForm.RTQCertificateURL.loading"
                :uploaded="editDriverForm.RTQCertificateURL.uploaded" :picUrl="editDriverForm.RTQCertificateURL.picUrl"
                :isModel="false" @update:file="updateFile" :fileType="'Driver'" @change="
                  uploadConveyLicence(editDriverForm.RTQCertificateURL, false)
                  " @deleteImg="deleteImg(editDriverForm.RTQCertificateURL)">
              </UploadImg>
              <div class="upText">
                道路运输从业资格证
                <i @click="imgCase(11)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="legalPersonBankCard" :loading="editDriverForm.legalPersonBankCard.loading"
                :uploaded="editDriverForm.legalPersonBankCard.uploaded"
                :picUrl="editDriverForm.legalPersonBankCard.picUrl" :fileType="'Driver'" :isModel="false"
                @update:file="updateFile" @change="
                  uploadLegalPersonBankCard(
                    editDriverForm.legalPersonBankCard,
                    false
                  )
                  " @deleteImg="deleteImg(editDriverForm.legalPersonBankCard)">
              </UploadImg>
              <div class="upText">
                银行卡照片
                <i @click="imgCase(5)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="uploadHandIdCard" :loading="editDriverForm.DriverIDCardPhoto.loading"
                :uploaded="editDriverForm.DriverIDCardPhoto.uploaded" :picUrl="editDriverForm.DriverIDCardPhoto.picUrl"
                :isModel="false" @update:file="updateFile" :fileType="'Driver'" @change="
                  uploadHandIdCard(editDriverForm.DriverIDCardPhoto, false)
                  " @deleteImg="deleteImg(editDriverForm.DriverIDCardPhoto)">
              </UploadImg>
              <div class="upText">
                手持身份证照
                <i @click="imgCase(13)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg ref="uploaDriverPhoto" :loading="editDriverForm.DriverPhotoURL.loading"
                :uploaded="editDriverForm.DriverPhotoURL.uploaded" :picUrl="editDriverForm.DriverPhotoURL.picUrl"
                :isModel="false" @update:file="updateFile" :fileType="'Driver'"
                @change="uploaDriverPhoto(editDriverForm.DriverPhotoURL, false)"
                @deleteImg="deleteImg(editDriverForm.DriverPhotoURL)">
              </UploadImg>
              <div class="upText">
                人车合影(车牌可见)
                <i @click="imgCase(12)" class="el-icon-question" style="cursor: pointer; color: rgb(230, 160, 62)">
                  案例
                </i>
              </div>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="flag.editDriverPhoto = false">取 消</el-button>
          <el-button type="primary" @click="confirmEdit('editDriverForm')">保存</el-button>
        </span>
      </el-dialog>
      <!-- 图片预览 -->
      <el-dialog :visible.sync="flag.showImg" title="图片预览">
        <el-image :src="currentImgUrl" :preview-src-list="[currentImgUrl]"></el-image>
      </el-dialog>
      <!-- 查看账户案例 -->
      <el-dialog class="addDialog" :visible.sync="flag.isCase" width="840px" :close-on-click-modal="false">
        <span slot="title" class="dialog-title"> 查看案例 </span>
        <div v-for="(item, index) in caselist" :key="index">
          <img style="width: 800px" :src="item.SampleURL" />
          <div style="width: 100; text-align: center; font-size: 16px; color: #000">
            {{ item.Name }}
          </div>
        </div>
      </el-dialog>
      <!-- 批量上传 -->
      <el-dialog class="isBatch" :visible.sync="flag.isBatch" width="1360px" :close-on-click-modal="false">
        <el-row slot="title">
          <el-col :span="10">
            <span class="dialog-title"> 批量上传 </span>
          </el-col>
          <el-col :span="12">
            <h1 style="color: red; font-size: 15px">
              导入运力超过100时,请先暂存再勾选导入！
            </h1>
          </el-col>
        </el-row>

        <div style="margin: 0 0 10px 0; display: flex">
          <div>
            <span style="padding: 0 10px">是否在运力池</span>
            <el-select v-model="batchListAttribute" placeholder="请选择" @change="batchListAttributeChange"
              style="margin-left: 10px; width: 130px" size="medium">
              <el-option label="全部" value="0"></el-option>
              <el-option label="存在" value="1"></el-option>
              <el-option label="不存在" value="2"></el-option>
            </el-select>
            <span style="padding: 0 10px">是否我的运力</span>
            <el-select v-model="IsMyTC" placeholder="请选择" @change="isMyTCChange" style="margin: 0px 10px; width: 130px"
              size="medium">
              <el-option label="全部" value="全部"></el-option>
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
            <el-button type="primary" size="medium" icon="el-icon-wallet"
              @click="flag.showDriverInfo = !flag.showDriverInfo">上传司机表格</el-button>
            <el-button type="primary" :disabled="tableData.length == 0 ? true : isSubmit" size="medium"
              icon="el-icon-upload" @click="syncImgUrl">同步图片</el-button>
            <el-button type="primary" :disabled="tableData.length == 0 ? true : isSubmit" size="medium"
              icon="el-icon-upload" @click="save('working', false)">暂存</el-button>
            <el-button type="primary" :disabled="tableData.length == 0 ? true : isSubmit" size="medium"
              icon="el-icon-check" @click="save('submit', false)">提交资料(覆盖旧资料)</el-button>
            <el-button type="primary" :disabled="tableData.length == 0 ? true : isSubmit" size="medium"
              icon="el-icon-check" @click="save('submit', true)">提交资料(引用运力池)</el-button>
          </div>
          <el-tooltip placement="left">
            <div slot="content">
              通过 选择 是否在运力池 选项去做相应操作<br />
              1.选择 全部及不存在---则需要
              先进行“同步图片”---才能进行接下来的操作<br />
              2.选择 存在---点击“提交资料(覆盖旧资料)”---提示先进行“同步图片”
              <br />
              3.选择 存在---点击“提交资料(引用运力池)”----则可以直接引用
              不需要同步
            </div>
            <div style="margin: 0px 20px; color: #0f5fff; cursor: pointer">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>
        <div style="margin: 5px 0px">
          <el-button type="danger" size="medium" icon="el-icon-delete" @click="selectDeleteEvent()">选中错误运力</el-button>
          <el-button type="danger" size="medium" icon="el-icon-delete" @click="deleteSelectEvent()">删除选中</el-button>
          <el-button type="primary" size="medium" icon="el-icon-download" @click="downloadExcel()"
            :disabled="!tableData.length">下载表格</el-button>
          <template>
            <el-radio-group v-model="driverIdentityType" @change="tabChange">
              <el-radio label="1" style="margin-left: 20px">司机</el-radio>
              <el-radio label="2">代收人</el-radio>
            </el-radio-group>
          </template>
        </div>
        <vxe-table border auto-resize resizable :scroll-y="{ enabled: true }" show-header-overflow :data="tableData"
          :edit-config="{ trigger: 'click', mode: 'cell' }" :loading="loading" height="620px" ref="xTable">
          <vxe-column type="checkbox" align="center" width="40" fixed="left"></vxe-column>
          <vxe-column type="seq" width="50" align="center" field="ID" title="序号" fixed="left"></vxe-column>
          <vxe-table-column width="150" align="center" field="DriverName" title="司机姓名"
            :edit-render="{ name: 'input', autoselect: true }" fixed="left">
            <template #edit="{ row }">
              <el-input v-model="row.DriverName" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="140" align="center" field="TelPhone" title="司机手机号码"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <el-input v-model="row.TelPhone" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="180" align="center" field="DriverIDCard" title="司机身份证号"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <el-input v-model="row.DriverIDCard" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="120" align="center" field="VehicleCarNumber" title="车牌号"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <el-input v-model="row.VehicleCarNumber" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="150" align="center" field="DriverIDCardStartDate" title="身份证起始日期" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.DriverIDCardStartDate" type="date"></vxe-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="150" align="center" field="DriverIDCardEndDate" title="身份证有效期" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.DriverIDCardEndDate" type="date"></vxe-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="140" align="center" field="DriverSex" title="性别"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #default="{ row }">
              <span>{{ row.DriverSex }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.DriverSexCode" clearable>
                <vxe-option v-for="item in sexList" :key="item.Code" :value="item.Code" :label="item.Name"></vxe-option>
              </vxe-select>
            </template>
          </vxe-table-column>
          <vxe-table-column width="140" align="center" field="Ethnic" title="民族"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <el-input v-model="row.Ethnic" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="140" align="center" field="Birthday" title="出生日期"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <vxe-input v-model="row.Birthday" type="date"></vxe-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="140" align="center" field="Address" title="地址"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <el-input v-model="row.Address" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>

          <vxe-table-column width="140" align="center" field="DriverLicenseType" title="准驾类型"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #default="{ row }">
              <span>{{ row.DriverLicenseType }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.DriverLicenseType" clearable>
                <vxe-option v-for="item in driverLicenseTypeList" :key="item.Code" :value="item.Code"
                  :label="item.Name"></vxe-option>
              </vxe-select>
            </template>
          </vxe-table-column>

          <vxe-table-column width="150" align="center" field="DriverLicenseDateStart" title="驾驶证起始日期" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.DriverLicenseDateStart" type="date"></vxe-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="150" align="center" field="DriverLicenseDateEnd" title="驾驶证有效期" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.DriverLicenseDateEnd" type="date"></vxe-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="160" align="center" field="DriverLicesenAuthority" title="驾驶证发证机关"
            :edit-render="{ name: 'input', autoselect: true }">
            <template #edit="{ row }">
              <el-input v-model="row.DriverLicesenAuthority" type="text" @input="tableInp(row)"></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column width="190" align="center" field="BankCardNo" title="银行卡号"
            :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
          <vxe-table-column width="190" align="center" field="RTQCertificate" title="从业资格证"
            :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
          <vxe-table-column width="100" align="center" field="IsInTCName" title="平台运力池">
            <template #default="{ row }">
              <el-tag :type="row.IsInTCName == '不存在' ? 'danger' : 'success'">
                {{ row.IsInTCName }}
              </el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column width="100" align="center" field="IsMyTC" title="我的运力">
            <template #default="{ row }">
              <el-tag :type="row.IsMyTC == '否' ? 'danger' : 'success'">
                {{ row.IsMyTC }}
              </el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="DriverCardOnURL" title="身份证(正面)">
            <template #default="{ row }">
              <el-image v-if="row.DriverCardOnURL" style="width: 200; height: 80px" :src="row.DriverCardOnURL"
                :preview-src-list="[row.DriverCardOnURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="DriverCardBackURL" title="身份证(反面)">
            <template #default="{ row }">
              <el-image v-if="row.DriverCardBackURL" style="width: 200; height: 80px" :src="row.DriverCardBackURL"
                :preview-src-list="[row.DriverCardBackURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="DriverLicenseOnURL" title="驾驶证(正页)">
            <template #default="{ row }">
              <el-image v-if="row.DriverLicenseOnURL" style="width: 200; height: 80px" :src="row.DriverLicenseOnURL"
                :preview-src-list="[row.DriverLicenseOnURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="DriverLicenseBackURL" title="驾驶证(副页)">
            <template #default="{ row }">
              <el-image v-if="row.DriverLicenseBackURL" style="width: 200; height: 80px" :src="row.DriverLicenseBackURL"
                :preview-src-list="[row.DriverLicenseBackURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="RTQCertificateURL" title="道路运输从业资格证">
            <template #default="{ row }">
              <el-image v-if="row.RTQCertificateURL" style="width: 200; height: 80px" :src="row.RTQCertificateURL"
                :preview-src-list="[row.RTQCertificateURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="BankCardURL" title="银行卡">
            <template #default="{ row }">
              <el-image v-if="row.BankCardURL" style="width: 200; height: 80px" :src="row.BankCardURL"
                :preview-src-list="[row.BankCardURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="DriverIDCardPhoto" title="人员手持身份证">
            <template #default="{ row }">
              <el-image v-if="row.DriverIDCardPhoto" style="width: 200; height: 80px" :src="row.DriverIDCardPhoto"
                :preview-src-list="[row.DriverIDCardPhoto]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="DriverPhotoURL" title="人车合影">
            <template #default="{ row }">
              <el-image v-if="row.DriverPhotoURL" style="width: 200; height: 80px" :src="row.DriverPhotoURL"
                :preview-src-list="[row.DriverPhotoURL]">
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="ErrorMsg" title="导入错误信息" fixed="right">
            <template #default="{ row }">
              <el-tooltip v-if="row.IsInTCName == '不存在'" class="item" effect="dark" placement="top-start">
                <div slot="content">{{ row.ErrorMsg }}</div>
                <div style="color: red">{{ row.ErrorMsg }}</div>
              </el-tooltip>
              <el-tooltip v-else class="item" effect="dark" :content="row.ErrorMsg" placement="top-start">
                <div style="color: red">{{ row.ErrorMsg }}</div>
              </el-tooltip>
              <!-- <div style="color:red" v-if="row.IsInTCName == '不存在'">

                {{
      row.DriverCardOnURL === null
        ? `${row.ErrorMsg}缺少证件图片`
        : row.DriverCardBackURL === null
          ? `${row.ErrorMsg}缺少证件图片`
          : row.DriverLicenseOnURL === null && driverIdentityType == '1'
            ? `${row.ErrorMsg}缺少证件图片`
            : row.DriverLicenseBackURL === null && driverIdentityType == '1'
              ? `${row.ErrorMsg}缺少证件图片`
              : row.ErrorMsg
    }}
              </div> -->
              <!-- <div style="color:red" v-else>{{ row.ErrorMsg }}</div> -->
            </template>
          </vxe-table-column>
          <vxe-table-column width="200" align="center" field="Remark" title="备注" fixed="right">
            <template #default="{ row }">
              <div style="color: red">{{ row.Remark }}</div>
            </template>
          </vxe-table-column>
          <vxe-table-column width="100" align="center" title="操作" fixed="right">
            <template #default="{ row }">
              <el-button @click="editDriverInfo(row)" type="primary" icon="el-icon-edit" size="small" title="编辑">编辑
              </el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </el-dialog>
    </div>
    <!-- 同步照片进度条 -->
    <div v-if="flag.progressIng" style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: white;
        height: calc(100vh - 106px);
      ">
      <el-progress type="circle" :percentage="flag.progress"></el-progress>
      <p style="margin-top: 8px; font-weight: 700; font-size: 18px">
        同步图片中...
      </p>
    </div>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import {
  DriverExcel,
  driversDraftInfo,
  saveDraftDriver,
  deleteDrivers,
  uploadDriver,
  upLoadImgByUrl,
  realnameList,
  DeleteDraft,
  PutDriverIsTroubleBaseInfoAsync,
  ExportFailDriver,
  DwonloadTableDriver
} from "@/api/transport/driverManage/bulkImport";
import { realname } from "@/api/transport/driverManage/add";
import { GetFileInfo, getDataDict, uploadImg } from "@/api/common/common";
import { uploadLegalPersonBankCard } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import { TCBaseInfo } from "@/api/transport/driverManage/index";
import { GenNonDuplicateID } from "@/utils/utils";
import {
  uploadIdFace,
  uploadIdBack,
  uploadDriverFace,
  uploadDriverBack,
  uploadConveyLicence,
  uploaDriverPhoto,
  uploadHandIdCard,
} from "@/api/transport/driverManage/add";
import { GetSampleInfo } from "@/api/common/common";
import { upExport } from "@/api/finance/account/index";
import { mapGetters } from "vuex";
// 上传数组
let typeOne = [];
let typeTwo = [];
let typeThree = [];
let typeFour = [];
let typeFive = [];
let typeSix = [];
let typeSeven = [];
let typeEight = [];
// 定时器
let interval = "";
export default {
  data() {
    return {
      //当前正在操作的文件
      currentFile: "",
      //正在操作表格的哪行
      currentRow: "",
      //正在编辑的表格item的图片url
      currentEditItem: {
        DriverCardOnURL: "",
        DriverCardBackURL: "",
        DriverLicenseOnURL: "",
        DriverLicenseBackURL: "",
        RTQCertificateURL: "",
        BankCardURL: "",
        DriverPhotoURL: "",
        DriverIDCardPhoto: "",
      },
      //正在预览的图片
      currentImgUrl: "",
      flag: {
        clickSyncImgUrl: false, //是否点过同步图片
        progressIng: false, //同步照片中
        progress: 0, //同步照片的进度
        //是否显示导入司机信息dialog
        showDriverInfo: false,
        //是否显示导入司机照片dialog
        showDriverPhoto: false,
        //是否显示编辑司机照片dialog
        editDriverPhoto: false,
        // 图片预览
        showImg: false,
        riskProgress: 0,
        // 案例
        isCase: false,
        // 批量上传
        isBatch: false,
      },
      tableData: [], //表格数据
      bulkImportList: [], //批量导入的数据
      // 司机证件图片
      editDriverForm: {
        // 身份证正面
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 身份证反面
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 驾驶证正面
        driverFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 驾驶证反面
        driverBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 道路运输从业资格证
        RTQCertificateURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        // 银行卡
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        DriverPhotoURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        DriverIDCardPhoto: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 表单校验
      editDriverRule: {
        "idFace.url": [
          { required: true, message: "请上传身份证正面", trigger: "change" },
        ],
        "idBack.url": [
          { required: true, message: "请上传身份证反面", trigger: "change" },
        ],
        "driverFace.url": [
          { required: true, message: "请上传驾驶证正面", trigger: "change" },
        ],
        "driverBack.url": [
          { required: true, message: "请上传驾驶证反面", trigger: "change" },
        ],
      },
      // 是否正在上传
      uploading: false,
      // 是否显示加载框
      loading: false,
      // 上传完成的列表
      upSuccess: [],
      // 案例列表
      caselist: [],
      // 草稿箱列表
      batchData: [],
      batchDataLoading: false,
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 是否提交
      isSubmit: false,
      // 运力池
      IsInTCList: [
        {
          Name: "全部",
          Code: "",
        },
        {
          Name: "不存在",
          Code: 0,
        },
        {
          Name: "存在",
          Code: 1,
        },
      ],

      //司机属性
      IdentityTypeList: [
        {
          Name: "全部",
          Code: "",
        },
        {
          Name: "司机",
          Code: "1",
        },
        {
          Name: "代收人",
          Code: "2",
        },
      ],

      // 搜索参数
      driverForm: {
        IsInTCCode: "",
        DriverName: "",
        TelPhone: "",
        IdentityType: "",
      },
      sexList: [], //性别列表
      driverLicenseTypeList: [], //准驾类型列表
      IsMyTC: "全部", //是否是我的运力  是和否
      batchListAttribute: "0", //控制批量上传是否显示存在运力池 0全部 1存在 2不存在
      allTableData: [], //完整的tableData
      tableHeight: null,
      driverIdentityType: "1", //司机属性 1-司机，2-代收人
    };
  },
  computed: {
    ...mapGetters(["getIsCarCaptain"]), // 如果是车队长,隐藏界面部分按钮
  },
  created() {
    // 查看草稿箱司机信息
    this.driversDraftInfo();
    // 性别
    getDataDict({ type: 9 }).then((res) => {
      this.sexList = res.patterSetInfo;
      this.sexList.forEach((item, index) => {
        if (item.Code == 2) {
          this.sexList.splice(index, 1);
        }
      });
    });
    // 准驾类型
    getDataDict({ type: 7 }).then((res) => {
      this.driverLicenseTypeList = res.patterSetInfo;
    });

    // 获取table表格高度
    this.getTableHeight();
  },
  methods: {
    // 下载Excel
    downloadExcel() {
      this.loading = true;
      DwonloadTableDriver(this.tableData)
        .then((res) => {
          window.location.href = res.data
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tabChange(val) {
      for (let i = 0; i < this.tableData.length; i++) {
        let it = this.tableData[i];
        let isHasFlag = Object.keys(it).includes("flag"); // 判断是否存在flag属性
        if (it.IsInTCName == "不存在") {
          it.IdentityType = val;
          if (isHasFlag) {
            if (
              it.DriverName == "" ||
              it.DriverIDCard == "" ||
              it.TelPhone == "" ||
              it.DriverCardOnURL == null ||
              it.DriverCardBackURL == null
            ) {
              it.flag = false;
            } else {
              it.flag = true;
            }
            if (
              it.IdentityType == "1" &&
              (it.DriverLicenseOnURL == null || it.DriverLicenseBackURL == null)
            ) {
              it.flag = false;
            } else {
              it.flag = true;
            }
          }
          it.ErrorMsg = "";
          it.Remark = "";
          it.ErrorMsg = this.getErrorMsg(it);
          it.uid = GenNonDuplicateID();
        }
      }
      this.submitClick();
    },
    getErrorMsg(row) {
      return row.DriverCardOnURL === null
        ? `${row.ErrorMsg}缺少证件图片`
        : row.DriverCardBackURL === null
          ? `${row.ErrorMsg}缺少证件图片`
          : row.DriverLicenseOnURL === null && this.driverIdentityType == "1"
            ? `${row.ErrorMsg}缺少证件图片`
            : row.DriverLicenseBackURL === null && this.driverIdentityType == "1"
              ? `${row.ErrorMsg}缺少证件图片`
              : row.ErrorMsg;
    },
    //是否我的运力筛选 this.batchListAttribute 0:全部 1：存在 2：不存在
    isMyTCChange(e) {
      if (e == "全部") {
        if (this.batchListAttribute == 0) {
          this.tableData = JSON.parse(JSON.stringify(this.allTableData));
        }
        if (this.batchListAttribute == 1) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  item.IsInTCName == "存在" || item.IsInTCName == "已存在"
              )
            )
          );
        }
        if (this.batchListAttribute == 2) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsInTCName == "不存在")
            )
          );
        }
      }
      if (e == "是") {
        if (this.batchListAttribute == 0) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsMyTC == "是")
            )
          );
        }
        if (this.batchListAttribute == 1) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "是"
              )
            )
          );
        }
        if (this.batchListAttribute == 2) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "是"
              )
            )
          );
        }
      }
      if (e == "否") {
        if (this.batchListAttribute == 0) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsMyTC == "否")
            )
          );
        }
        if (this.batchListAttribute == 1) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "否"
              )
            )
          );
        }
        if (this.batchListAttribute == 2) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "否"
              )
            )
          );
        }
      }
    },
    //是否在运力池筛选
    batchListAttributeChange(e) {
      debugger;
      if (e == 0) {
        if (this.IsMyTC == "全部") {
          this.tableData = JSON.parse(JSON.stringify(this.allTableData));
        }
        if (this.IsMyTC == "是") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsMyTC == "是")
            )
          );
        }
        if (this.IsMyTC == "否") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsMyTC == "否")
            )
          );
        }
      }
      if (e == 1) {
        if (this.IsMyTC == "全部") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  item.IsInTCName == "存在" || item.IsInTCName == "已存在"
              )
            )
          );
          console.log(this.allTableData);
        }
        if (this.IsMyTC == "是") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "是"
              )
            )
          );
        }
        if (this.IsMyTC == "否") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "否"
              )
            )
          );
        }
      }
      if (e == 2) {
        if (this.IsMyTC == "全部") {
          console.log(this.allTableData);
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsInTCName == "不存在")
            )
          );
        }
        if (this.IsMyTC == "是") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "是"
              )
            )
          );
        }
        if (this.IsMyTC == "否") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "否"
              )
            )
          );
        }
      }
    },
    //每页条数改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.driversDraftInfo();
    },
    //同步url
    syncImgUrl() {
      this.flag.progressIng = true;
      let willSyncList = []; //将要进行同步的数组
      let maxCount = 10; //一批上传一百张
      //先计算出要发出去多少请求
      this.tableData.forEach((item, index) => {
        if (item.BankCardURL) {
          willSyncList.push({
            type: "BankCardURL",
            url: item.BankCardURL,
            index: index,
          });
        }
        if (item.DriverCardBackURL) {
          willSyncList.push({
            type: "DriverCardBackURL",
            url: item.DriverCardBackURL,
            index: index,
          });
        }
        if (item.DriverCardOnURL) {
          willSyncList.push({
            type: "DriverCardOnURL",
            url: item.DriverCardOnURL,
            index: index,
          });
        }
        if (item.DriverLicenseBackURL) {
          willSyncList.push({
            type: "DriverLicenseBackURL",
            url: item.DriverLicenseBackURL,
            index: index,
          });
        }
        if (item.DriverLicenseOnURL) {
          willSyncList.push({
            type: "DriverLicenseOnURL",
            url: item.DriverLicenseOnURL,
            index: index,
          });
        }
        if (item.RTQCertificateURL) {
          willSyncList.push({
            type: "RTQCertificateURL",
            url: item.RTQCertificateURL,
            index: index,
          });
        }
      });
      //将要上传的url数组， 一批可上传多少张， 第几批
      this.batchSyncImgUrl(willSyncList, maxCount, 0);
    },
    //批量同步图片
    batchSyncImgUrl(willSyncList, maxCount, index) {
      let progress = index * maxCount; //已同步的次数
      let batchList = JSON.parse(JSON.stringify(willSyncList)).splice(
        index * maxCount,
        maxCount
      );
      batchList.forEach((item, inx) => {
        //说明这一批上传完了
        if (inx === batchList.length) {
          return;
        }
        if (item.type === "BankCardURL") {
          upLoadImgByUrl({ FileUrl: item.url, FileType: "Driver" })
            .then((res) => {
              this.tableData[item.index].BankCardURL = res.data;
            })
            .catch(() => {
              this.tableData[item.index].BankCardURL = "";
              this.tableData[item.index].ErrorMsg =
                (this.tableData[item.index].ErrorMsg
                  ? this.tableData[item.index].ErrorMsg + "、"
                  : "") + "银行卡证照有误";
            })
            .finally(() => {
              progress = progress + 1;
              this.syncComplete(willSyncList, progress, maxCount, index);
            });
        }
        if (item.type === "DriverCardBackURL") {
          upLoadImgByUrl({ FileUrl: item.url, FileType: "Driver" })
            .then((res) => {
              this.tableData[item.index].DriverCardBackURL = res.data;
            })
            .catch(() => {
              this.tableData[item.index].DriverCardBackURL = "";
              this.tableData[item.index].ErrorMsg =
                (this.tableData[item.index].ErrorMsg
                  ? this.tableData[item.index].ErrorMsg + "、"
                  : "") + "身份证(反面)证照有误";
              console.log("身份证(反面)错误", this.tableData[item.index], item);
            })
            .finally(() => {
              progress = progress + 1;
              this.syncComplete(willSyncList, progress, maxCount, index);
            });
        }
        if (item.type === "DriverCardOnURL") {
          upLoadImgByUrl({ FileUrl: item.url, FileType: "Driver" })
            .then((res) => {
              this.tableData[item.index].DriverCardOnURL = res.data;
            })
            .catch(() => {
              this.tableData[item.index].DriverCardOnURL = "";
              this.tableData[item.index].ErrorMsg =
                (this.tableData[item.index].ErrorMsg
                  ? this.tableData[item.index].ErrorMsg + "、"
                  : "") + "身份证(正面)证照有误";
            })
            .finally(() => {
              progress = progress + 1;
              this.syncComplete(willSyncList, progress, maxCount, index);
            });
        }
        if (item.type === "DriverLicenseBackURL") {
          upLoadImgByUrl({ FileUrl: item.url, FileType: "Driver" })
            .then((res) => {
              this.tableData[item.index].DriverLicenseBackURL = res.data;
            })
            .catch(() => {
              this.tableData[item.index].DriverLicenseBackURL = "";
              this.tableData[item.index].ErrorMsg =
                (this.tableData[item.index].ErrorMsg
                  ? this.tableData[item.index].ErrorMsg + "、"
                  : "") + "驾驶证(反面)证照有误";
            })
            .finally(() => {
              progress = progress + 1;
              this.syncComplete(willSyncList, progress, maxCount, index);
            });
        }
        if (item.type === "DriverLicenseOnURL") {
          upLoadImgByUrl({ FileUrl: item.url, FileType: "Driver" })
            .then((res) => {
              this.tableData[item.index].DriverLicenseOnURL = res.data;
            })
            .catch(() => {
              this.tableData[item.index].DriverLicenseOnURL = "";
              this.tableData[item.index].ErrorMsg =
                (this.tableData[item.index].ErrorMsg
                  ? this.tableData[item.index].ErrorMsg + "、"
                  : "") + "驾驶证(正面)证照有误";
            })
            .finally(() => {
              progress = progress + 1;
              this.syncComplete(willSyncList, progress, maxCount, index);
            });
        }
        if (item.type === "RTQCertificateURL") {
          upLoadImgByUrl({ FileUrl: item.url, FileType: "Driver" })
            .then((res) => {
              this.tableData[item.index].RTQCertificateURL = res.data;
            })
            .catch(() => {
              this.tableData[item.index].RTQCertificateURL = "";
              this.tableData[item.index].ErrorMsg =
                (this.tableData[item.index].ErrorMsg
                  ? this.tableData[item.index].ErrorMsg + "、"
                  : "") + "道路运输从业资格证证照有误";
            })
            .finally(() => {
              progress = progress + 1;
              this.syncComplete(willSyncList, progress, maxCount, index);
            });
        }
      });
    },
    //是否同步完毕回调
    syncComplete(willSyncList, progress, maxCount, index) {
      //全部上传完了
      this.flag.progress = parseInt((progress / willSyncList.length) * 100);
      if (progress == willSyncList.length) {
        //完成
        this.flag.progressIng = false;
        this.flag.progress = 0;
        this.flag.progressCount = 0;
        this.flag.clickSyncImgUrl = true;
      }
      //这一批上传完了，继续递归
      if (progress === maxCount * (index + 1)) {
        this.batchSyncImgUrl(willSyncList, maxCount, index + 1);
      }
    },
    // 下载司机表格
    downloadClick() {
      this.loading = true;
      let data = {
        fileType: "草稿箱司机导出",
        fileName: "草稿箱司机列表",
        IsInTCCode: this.driverForm.IsInTCCode,
        Name: this.driverForm.DriverName,
        TelPhone: this.driverForm.TelPhone,
        IdentityType: this.driverForm.IdentityType,
      };
      upExport({ Json: JSON.stringify(data) })
        .then((res) => {
          window.location.href = res.pathList[0].pathName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 清空搜索
    resetForm() {
      this.driverForm = {
        IsInTCCode: "",
        DriverName: "",
        TelPhone: "",
        IdentityType: "",
      };
      this.pagination.page = 1;
      this.driversDraftInfo();
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.driversDraftInfo();
    },
    // 批量删除
    delClick() {
      let arr = [];
      this.tableData.forEach((item) => {
        let data = {
          phone: item.TelPhone,
        };
        arr.push(data);
      });
      this.$confirm("此操作将永久删除选中的数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteDrivers(arr).then((res) => {
          this.tableData = [];
          this.driversDraftInfo();
        });
      });
    },
    // 一键删除
    delClickAll() {
      this.$confirm("此操作将永久删除所有数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeleteDraft().then((res) => {
          this.batchData = [];
          this.driversDraftInfo();
          this.$message.success("删除成功");
        });
      });
    },
    // 编辑表格
    tableInp(item) {
      for (let i in item) {
        let data = item[i];
        if (data == "") {
          if (i == "DriverName" || i == "DriverIDCard" || i == "TelPhone") {
            this.isSubmit = true;
            return;
          } else {
            this.isSubmit = false;
          }
        }
      }
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.driversDraftInfo();
    },
    // 需要更改的数据
    selectChange(e) {
      this.tableData = e;
    },
    // 批量上传
    batchClick() {
      this.driverIdentityType = "1";
      let arr = [];
      if (this.tableData.length != 0) {
        for (let i in this.tableData) {
          let item = this.tableData[i];
          item.RTQCertificate = item.RTQCertificate || item.DriverIDCard;
          item.DriverLicenseDateStart = item.DriverLicenseDateStart.slice(
            0,
            10
          );
          item.DriverLicenseDateEnd = item.DriverLicenseDateEnd.slice(0, 10);
          item.Birthday = item.Birthday.slice(0, 10);
          item.DriverIDCardStartDate = item.DriverIDCardStartDate.slice(0, 10);
          item.DriverIDCardEndDate = item.DriverIDCardEndDate.slice(0, 10);
          item.IsMyTC = "是";
          //获取性别的code  传给后端的
          this.sexList.forEach((el) => {
            if (el.Code == item.DriverSex) {
              item.DriverSexCode = el.Code;
              item.DriverSex = el.Name;
            }
          });
          if (item.IsInTCName == "不存在") {
            if (
              item.DriverName == "" ||
              item.DriverIDCard == "" ||
              item.TelPhone == "" ||
              item.DriverCardOnURL == null ||
              item.DriverCardBackURL == null
            ) {
              item.flag = false;
            }

            if (
              item.IdentityType == "1" &&
              (item.DriverLicenseOnURL == null ||
                item.DriverLicenseBackURL == null)
            ) {
              item.flag = false;
            }

            arr.unshift(item);
          } else {
            arr.push(item);
          }

          //代收人逻辑
          if (i == 0) {
            this.driverIdentityType = item.IdentityType;
          } else {
            if (item.IdentityType != this.driverIdentityType) {
              this.$message.error(
                "当前未选择同一种司机身份（司机/代收人），请检索之后再试"
              );
              return;
            }
          }
        }
      }
      this.tableData = arr.reverse();
      this.tableData = this.tableData.map((it) => {
        it.uid = GenNonDuplicateID();
        return it;
      });
      this.allTableData = JSON.parse(JSON.stringify(this.tableData));

      console.log("   this.allTableData ", this.allTableData);
      this.submitClick();
      this.flag.isBatch = true;
    },
    // 查看草稿箱司机信息
    driversDraftInfo() {
      this.batchDataLoading = true;
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        IsInTCCode: this.driverForm.IsInTCCode,
        Name: this.driverForm.DriverName,
        TelPhone: this.driverForm.TelPhone,
        IdentityType: this.driverForm.IdentityType,
      };
      driversDraftInfo(data)
        .then((res) => {
          this.batchData = res.driver.map((item) => {
            item.DriverLicenseDateStart = item.DriverLicenseDateStart
              ? item.DriverLicenseDateStart.slice(0, 10)
              : "";
            item.DriverLicenseDateEnd = item.DriverLicenseDateEnd
              ? item.DriverLicenseDateEnd.slice(0, 10)
              : "";
            item.Birthday = item.Birthday ? item.Birthday.slice(0, 10) : "";
            item.DriverIDCardStartDate = item.DriverIDCardStartDate
              ? item.DriverIDCardStartDate.slice(0, 10)
              : "";
            item.DriverIDCardEndDate = item.DriverIDCardEndDate
              ? item.DriverIDCardEndDate.slice(0, 10)
              : "";

            return item;
          });
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.batchDataLoading = false;
        });
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    selectDeleteEvent() {
      let table = this.$refs.xTable;
      let removeList = [];
      this.tableData.forEach((item, inx) => {
        if (item.ErrorMsg) {
          removeList.push(item);
        }
      });
      table.setCheckboxRow(removeList, true);
    },
    // 删除已选中的数据
    deleteSelectEvent() {
      this.$confirm("是否删除已选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let table = this.$refs.xTable;
        let checkboxRecords = table.getCheckboxRecords();
        let removeList = [];
        checkboxRecords.forEach((checkbox) => {
          this.tableData.forEach((item, inx) => {
            if (item.uid == checkbox.uid) {
              this.tableData.splice(inx, 1);
              this.submitClick();
            }
          });
          this.allTableData.forEach((item, inx) => {
            if (item.uid == checkbox.uid) {
              this.allTableData.splice(inx, 1);
            }
          });

          removeList.push({
            userName: checkbox.DriverName,
            driverIDCard: checkbox.DriverIDCard,
            telPhone: checkbox.TelPhone,
          });
        });

        // 从草稿箱移除的数据
        PutDriverIsTroubleBaseInfoAsync(removeList).then((res) => {
          this.$message.success("删除成功");
        });
      });
    },
    // 判断是否可以提交
    submitClick() {
      let arr = [];
      this.tableData.forEach((item) => {
        arr.push(item.flag);
      });
      this.isSubmit = arr.includes(false);
    },
    //导入司机信息
    importDriverInfo(e) {
      this.loading = true;
      this.flag.showDriverInfo = false;
      const file = e.srcElement.files[0] || e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("SerialNum", this.tableData.length);
      uploadDriver(formData)
        .then((res) => {
          let arr = [];
          for (let i in res.data) {
            let item = res.data[i];
            item.index = i;
            item.RTQCertificate = item.RTQCertificate || item.DriverIDCard;
            //获取性别的code  传给后端的
            this.sexList.forEach((el) => {
              if (el.Name == item.DriverSex) {
                item.DriverSexCode = el.Code;
              }
            });
            arr.push(item.flag);
            this.tableData.push(item);
          }
          this.tableData = this.tableData.map((it) => {
            if (it.IsInTCName == "不存在") {
              it.ErrorMsg = this.getErrorMsg(it);
            }
            it.uid = GenNonDuplicateID();
            return it;
          });
          this.tableData = this.tableData.sort(function (a, b) {
            return b.ErrorMsg.length - a.ErrorMsg.length;
          });
          this.allTableData = JSON.parse(JSON.stringify(this.tableData));
          this.isSubmit = arr.includes(false);
          console.log(this.tableData, "===this.tableData");
        })
        .finally(() => {
          this.loading = false;
          //解决连续上传同一个文件不触发change事件
          this.$refs.driverInfo.value = "";
        });
    },
    //导入司机证照
    importDriverPhoto(e) {
      let file = e.srcElement.files || e.target.files;
      if (file.length > 100) {
        this.$message.error(`批量上传最多支持100张`);
        return;
      } else {
        if (this.bulkImportList.length > 100) {
          this.$message.error(`批量上传已上限`);
          return;
        }
      }
      for (let i = 0; i < file.length; i++) {
        let item = file[i];
        // 限制最大上传
        if (item.size > 8 * 1024 * 1024) {
          this.$message({
            message: `文件名：${item.name}的图片大于 8MB， 请上传小于 8MB 的图片`,
            type: "error",
            duration: 5000,
          });
          document.getElementById("inp").value = "";
        } else {
          // 获取文件名判断是否符合命名规范
          let name = item.name;
          // 校验名称是否正确
          let reg = /^[1][3,4,5,7,8,9][0-9]{9}[-][1,2,3,4,5,6,7,8]$/;
          // 上传图片
          if (!reg.test(name.substr(0, name.indexOf(".")))) {
            this.$message.error(`文件名：${item.name}的图片命名不符合规范`);
            document.getElementById("inp").value = "";
          } else {
            var phone = name.substring(0, 11);
            this.tableData.forEach((el) => {
              if (phone == el.TelPhone) {
                let data = {
                  url: window.URL.createObjectURL(item),
                  name: item.name,
                  size: item.size,
                  file: item,
                };
                this.bulkImportList.push(data);
              }
            });
          }
        }
      }
    },
    //把导入的司机证件照插入到表格中
    confirmImportDriverPhoto() {
      this.uploading = true;
      this.flag.riskProgress = 0;
      typeOne = [];
      typeTwo = [];
      typeThree = [];
      typeFour = [];
      typeFive = [];
      typeSix = [];
      typeSeven = [];
      typeEight = [];
      this.bulkImportList.forEach((item) => {
        // 获取文件名
        let name = item.name;
        //获取图片的类型 1.身份证正面 2.身份证反面 3.驾驶证正面 4.驾驶证反面 5.道路从业资格证 6.银行卡 7.手持身份证 8.人车合影
        let type = name.substring(name.indexOf(".") - 1, name.indexOf("."));
        // 根据不同的 type 值进行添加数据组
        if (type == 1) {
          typeOne.push(item);
        } else if (type == 2) {
          typeTwo.push(item);
        } else if (type == 3) {
          typeThree.push(item);
        } else if (type == 4) {
          typeFour.push(item);
        } else if (type == 5) {
          typeFive.push(item);
        } else if (type == 6) {
          typeSix.push(item);
        } else if (type == 7) {
          typeSeven.push(item);
        } else if (type == 8) {
          typeEight.push(item);
        }
      });
      if (typeOne.length != 0) {
        this.recursion(typeOne, 1);
      } else {
        if (typeTwo.length != 0) {
          this.recursion(typeTwo, 2);
        } else {
          if (typeThree.length != 0) {
            this.recursion(typeThree, 3);
          } else {
            if (typeFour.length != 0) {
              this.recursion(typeFour, 4);
            } else {
              if (typeFive.length != 0) {
                this.recursion(typeFive, 5);
              } else {
                if (typeSix.length != 0) {
                  this.recursion(typeSix, 6);
                } else {
                  if (typeSeven.length != 0) {
                    this.recursion(typeSeven, 7);
                  } else {
                    this.recursion(typeEight, 8);
                  }
                }
              }
            }
          }
        }
      }
      // 获取上传总长度
      let bulkInx = this.bulkImportList.length;
      // 定时器
      interval = setInterval(() => {
        // 获取正在传长度
        let upInx = this.upSuccess.length;
        if (this.flag.riskProgress >= 100) {
          this.flag.riskProgress = 100;
          clearInterval(interval);
          this.bulkImportList = [];
          this.uploading = false;
          this.flag.showDriverPhoto = false;
          this.flag.riskProgress = 0;
        } else {
          this.flag.riskProgress = Math.round((upInx / bulkInx) * 100);
        }
      }, 500);
    },
    /*
     * 列表分批次上传
     * listDat：数组列表
     * type：上传类型 1.身份证正面 2.身份证反面 3.驾驶证正面 4.驾驶证反面 5.道路从业资格证 6.银行卡 7.手持身份证 8.人车合影
     */
    recursion(listDat, type) {
      // 外层数组
      let arr = [];
      // 外层数组里面的子数组
      let arrInx = 0;
      // 子数组多少条数据
      let num = 20;
      // 循环有多少个二维数组
      listDat.forEach((listData, listInx) => {
        if (Number(listInx + 1) % num == 0) {
          arrInx++;
        }
      });
      // 循环二维数组长度创建二维数组
      for (let i = 0; i <= arrInx; i++) {
        arr[i] = [];
      }
      // 二维数组添加数据
      listDat.forEach((listData, listInx) => {
        arr[Math.floor(listInx / num)].push(listData);
      });
      // 循环最大数组获取每个子数组的长度及索引
      let arrlist = [];
      arr.forEach((arrItem, arrInx) => {
        let data = {
          length: arrItem.length,
          index: arrInx,
        };
        arrlist.push(data);
      });
      // 循环第一个子数组
      let arrIndex = arr[0].length;
      arr[0].forEach((item, itemInx) => {
        let fromData = new FormData();
        fromData.append("file", item.file);
        fromData.append("phone", item.name.substring(0, 11));
        // 身份证正面
        if (type == 1) {
          // 上传身份证正面
          uploadIdFace(fromData)
            .then((res) => {
              this.currentEditItem.DriverCardOnURL = res.drivers.idFaceUrl;
              this.upSuccess.push(res.drivers.idFaceUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.DriverCardOnURL = res.drivers.idFaceUrl;
                  el.DriverName = res.drivers.name;
                  el.DriverIDCard = res.drivers.num;
                  el.RTQCertificate = res.drivers.num;
                  el.DriverSex = res.drivers.sex;
                  el.Address = res.drivers.address ? res.drivers.address : "";
                  el.Ethnic = res.drivers.nationality
                    ? res.drivers.nationality
                    : "";
                  el.Birthday = res.drivers.birth
                    ? this.insertStr(res.drivers.birth, 4, "-", 7, "-")
                    : "";
                  el.flag = true;
                  this.submitClick();
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    this.recursion(typeTwo, 2);
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 上传身份证正面
                      uploadIdFace(dataFram)
                        .then((res) => {
                          self.currentEditItem.DriverCardOnURL =
                            res.drivers.idFaceUrl;
                          self.upSuccess.push(res.drivers.idFaceUrl);
                          document.getElementById("inp").value = "";
                          self.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.DriverCardOnURL = res.drivers.idFaceUrl;
                              el.DriverName = res.drivers.name;
                              el.DriverIDCard = res.drivers.num;
                              el.RTQCertificate = res.drivers.num;
                              el.DriverSex = res.drivers.sex;
                              el.Address = res.drivers.address
                                ? res.drivers.address
                                : "";
                              el.Ethnic = res.drivers.nationality
                                ? res.drivers.nationality
                                : "";
                              el.Birthday = res.drivers.birth
                                ? this.insertStr(
                                  res.drivers.birth,
                                  4,
                                  "-",
                                  7,
                                  "-"
                                )
                                : "";
                              el.flag = true;
                              this.submitClick();
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    this.recursion(typeTwo, 2);
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 身份证反面
        if (type == 2) {
          // 上传身份证反面
          uploadIdBack(fromData)
            .then((res) => {
              this.currentEditItem.DriverCardBackURL = res.drivers.idBackUrl;
              this.upSuccess.push(res.drivers.idBackUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.DriverCardBackURL = res.drivers.idBackUrl;
                  el.DriverIDCardStartDate = res.drivers.start_date
                    ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
                    : "";
                  el.DriverIDCardEndDate = res.drivers.end_date
                    ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                    : "";
                  el.flag = true;
                  this.submitClick();
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    this.recursion(typeThree, 3);
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 身份证反面
                      uploadIdBack(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverCardBackURL =
                            res.drivers.idBackUrl;
                          this.upSuccess.push(res.drivers.idBackUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.DriverCardBackURL = res.drivers.idBackUrl;
                              el.DriverIDCardStartDate = res.drivers.start_date
                                ? this.insertStr(
                                  res.drivers.start_date,
                                  4,
                                  "-",
                                  7,
                                  "-"
                                )
                                : "";
                              el.DriverIDCardEndDate = res.drivers.end_date
                                ? this.insertStr(
                                  res.drivers.end_date,
                                  4,
                                  "-",
                                  7,
                                  "-"
                                )
                                : "";
                              el.flag = true;
                              this.submitClick();
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    this.recursion(typeThree, 3);
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 驾驶证正页
        if (type == 3) {
          uploadDriverFace(fromData)
            .then((res) => {
              this.currentEditItem.DriverLicenseOnURL =
                res.drivers.driverFaceUrl;
              this.upSuccess.push(res.drivers.driverFaceUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.DriverLicenseOnURL = res.drivers.driverFaceUrl;
                  el.DriverLicenseType = res.drivers.vehicle_type
                    ? res.drivers.vehicle_type.substring(0, 2)
                    : "";
                  el.DriverLicenseDateEnd = res.drivers.end_date
                    ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
                    : "";
                  el.DriverLicenseDateStart = res.drivers.start_date
                    ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
                    : "";
                  el.flag = true;
                  this.submitClick();
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    this.recursion(typeFour, 4);
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 驾驶证正页
                      uploadDriverFace(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverLicenseOnURL =
                            res.drivers.driverFaceUrl;
                          this.upSuccess.push(res.drivers.driverFaceUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.DriverLicenseOnURL = res.drivers.driverFaceUrl;
                              el.DriverLicenseType = res.drivers.vehicle_type
                                ? res.drivers.vehicle_type.substring(0, 2)
                                : "";
                              el.DriverLicenseDateEnd = res.drivers.end_date
                                ? this.insertStr(
                                  res.drivers.end_date,
                                  4,
                                  "-",
                                  7,
                                  "-"
                                )
                                : "";
                              el.DriverLicenseDateStart = res.drivers.start_date
                                ? this.insertStr(
                                  res.drivers.start_date,
                                  4,
                                  "-",
                                  7,
                                  "-"
                                )
                                : "";
                              el.flag = true;
                              this.submitClick();
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    this.recursion(typeFour, 4);
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 驾驶证副页
        if (type == 4) {
          uploadDriverBack(fromData)
            .then((res) => {
              this.currentEditItem.DriverLicenseBackURL =
                res.drivers.driverBackUrl;
              this.upSuccess.push(res.drivers.driverBackUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.DriverLicenseBackURL = res.drivers.driverBackUrl;
                  el.DriverLicenseArchives = res.drivers.archive_no
                    ? res.drivers.archive_no
                    : "";
                  el.flag = true;
                  this.submitClick();
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeFive.length == 0) {
                      if (typeSix.length == 0) {
                        if (typeSeven.length == 0) {
                          this.recursion(typeEight, 8);
                        } else {
                          this.recursion(typeSeven, 7);
                        }
                      } else {
                        this.recursion(typeSix, 6);
                      }
                    } else {
                      this.recursion(typeFive, 5);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 驾驶证副页
                      uploadDriverBack(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverLicenseBackURL =
                            res.drivers.driverBackUrl;
                          this.upSuccess.push(res.drivers.driverBackUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.DriverLicenseBackURL =
                                res.drivers.driverBackUrl;
                              el.DriverLicenseArchives = res.drivers.archive_no
                                ? res.drivers.archive_no
                                : "";
                              el.flag = true;
                              this.submitClick();
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    if (typeFive.length == 0) {
                      if (typeSix.length == 0) {
                        if (typeSeven.length == 0) {
                          this.recursion(typeEight, 8);
                        } else {
                          this.recursion(typeSeven, 7);
                        }
                      } else {
                        this.recursion(typeSix, 6);
                      }
                    } else {
                      this.recursion(typeFive, 5);
                    }
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 道路从业资格证
        if (type == 5) {
          uploadConveyLicence(fromData)
            .then((res) => {
              this.currentEditItem.RTQCertificateURL = res.drivers;
              this.upSuccess.push(res.drivers);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.RTQCertificateURL = res.drivers;
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeSix.length == 0) {
                      if (typeSeven.length == 0) {
                        this.recursion(typeEight, 8);
                      } else {
                        this.recursion(typeSeven, 7);
                      }
                    } else {
                      this.recursion(typeSix, 6);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 驾驶证副页
                      uploadConveyLicence(dataFram)
                        .then((res) => {
                          this.currentEditItem.RTQCertificateURL = res.drivers;
                          this.upSuccess.push(res.drivers);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.RTQCertificateURL = res.drivers;
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    if (typeSix.length == 0) {
                      if (typeSeven.length == 0) {
                        this.recursion(typeEight, 8);
                      } else {
                        this.recursion(typeSeven, 7);
                      }
                    } else {
                      this.recursion(typeSix, 6);
                    }
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 银行卡
        if (type == 6) {
          uploadLegalPersonBankCard(fromData)
            .then((res) => {
              this.currentEditItem.BankCardURL = res.enterPrise.ImgUrl;
              this.upSuccess.push(res.enterPrise.ImgUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.BankCardURL = res.enterPrise.ImgUrl;
                  el.BankCardNo = res.enterPrise.card_num;
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeSeven.length == 0) {
                      this.recursion(typeEight, 8);
                    } else {
                      this.recursion(typeSeven, 7);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 银行卡
                      uploadLegalPersonBankCard(dataFram)
                        .then((res) => {
                          this.currentEditItem.BankCardURL =
                            res.enterPrise.ImgUrl;
                          this.upSuccess.push(res.enterPrise.ImgUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.BankCardURL = res.enterPrise.ImgUrl;
                              el.BankCardNo = res.enterPrise.card_num;
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    if (typeSeven.length == 0) {
                      this.recursion(typeEight, 8);
                    } else {
                      this.recursion(typeSeven, 7);
                    }
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 手持身份证
        if (type == 7) {
          uploadHandIdCard(fromData)
            .then((res) => {
              this.currentEditItem.DriverIDCardPhoto = res.drivers;
              this.upSuccess.push(res.drivers);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.DriverIDCardPhoto = res.drivers;
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeEight.length == 0) {
                      clearInterval(interval);
                      this.bulkImportList = [];
                      this.uploading = false;
                      this.flag.showDriverPhoto = false;
                    } else {
                      this.recursion(typeEight, 8);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 手持身份证
                      uploadHandIdCard(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverIDCardPhoto = res.drivers;
                          this.upSuccess.push(res.drivers);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.DriverIDCardPhoto = res.drivers;
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    if (typeEight.length == 0) {
                      clearInterval(interval);
                      this.bulkImportList = [];
                      this.uploading = false;
                      this.flag.showDriverPhoto = false;
                    } else {
                      this.recursion(typeEight, 8);
                    }
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 人车合影
        if (type == 8) {
          uploaDriverPhoto(fromData)
            .then((res) => {
              this.currentEditItem.DriverPhotoURL = res.drivers;
              this.upSuccess.push(res.drivers);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.TelPhone) {
                  el.DriverPhotoURL = res.drivers;
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    clearInterval(interval);
                    this.bulkImportList = [];
                    this.uploading = false;
                    this.flag.showDriverPhoto = false;
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append("phone", dataItem.name.substring(0, 11));
                      // 人车合影
                      uploaDriverPhoto(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverPhotoURL = res.drivers;
                          this.upSuccess.push(res.drivers);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.TelPhone) {
                              el.DriverPhotoURL = res.drivers;
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            if (arr[index] == undefined) return;
                            sum.call(self, index);
                          }
                        });
                    });
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
      });
    },
    //下载Excel模板
    download() {
      window.location.href =
        "https://res.gts56.com/fzkj/bsc/TempLate/%E5%8F%B8%E6%9C%BA%E8%BD%A6%E8%BE%86%E9%80%9A%E7%94%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20(%E7%BD%91%E7%BB%9C%E8%B4%A7%E8%BF%90%E5%B9%B3%E5%8F%B0%E9%80%82%E7%94%A8).xlsx";
    },
    //上传Excel
    DriverExcel() {
      this.$refs.driverInfo.click();
      this.$refs.driverInfo.value = "";
    },
    //编辑司机信息
    editDriverInfo(item) {
      this.tableData.forEach((data, index) => {
        if (data.DriverIDCard == item.DriverIDCard) {
          this.currentRow = index;
        }
      });
      this.flag.editDriverPhoto = !this.flag.editDriverPhoto;
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //上传身份证正面
    uploadIdFace(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.idFace.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("身份证(正面)证照有误", "")
              : "";
          })
          .catch((e) => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.idFace.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadIdFace(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.idFaceUrl;
            this.editDriverForm.idFace.picUrl = res.drivers.idFaceUrl;
            this.tableData[this.currentRow].DriverSex = res.drivers.sex
              ? res.drivers.sex
              : "";
            this.tableData[this.currentRow].DriverName = res.drivers.name
              ? res.drivers.name
              : "";
            this.tableData[this.currentRow].DriverIDCard = res.drivers.num
              ? res.drivers.num
              : "";
            this.tableData[this.currentRow].RTQCertificate = res.drivers.num
              ? res.drivers.num
              : "";
            this.tableData[this.currentRow].Address = res.drivers.address
              ? res.drivers.address
              : "";
            this.tableData[this.currentRow].Ethnic = res.drivers.nationality
              ? res.drivers.nationality
              : "";
            this.tableData[this.currentRow].Birthday = res.drivers.birth
              ? this.insertStr(res.drivers.birth, 4, "-", 7, "-")
              : "";
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("身份证(正面)证照有误", "")
              : "";
            //上传证件照后---如身份证号识别发生变化了---  需要重新查新的身份证号是否在运力池
            if (this.tableData[this.currentRow].DriverIDCard) {
              TCBaseInfo({
                idcard: this.tableData[this.currentRow].DriverIDCard,
              }).then((res) => {
                if (res.data.BaseInfo == null) {
                  //没在运力池
                  this.tableData[this.currentRow].IsInTCName = "不存在";
                } else {
                  //存在运力池
                  this.tableData[this.currentRow].IsInTCName = "存在";
                }
              });
            }
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.idFace.dialog();
            this.submitClick();
          });
      }
    },
    //上传身份证反面
    uploadIdBack(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.idBack.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("身份证(反面)证照有误", "")
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.idBack.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadIdBack(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.idBackUrl;
            this.editDriverForm.idBack.picUrl = res.drivers.idBackUrl;
            this.tableData[this.currentRow].DriverIDCardStartDate = res.drivers
              .start_date
              ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
              : "";
            this.tableData[this.currentRow].DriverIDCardEndDate = res.drivers
              .end_date
              ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
              : "";
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("身份证(反面)证照有误", "")
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.idBack.dialog();
            this.submitClick();
          });
      }
    },
    //识别日期插入-
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure =
        soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return (
        soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition)
      );
    },
    //上传驾驶证正面
    uploadDriverFace(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.driverFace.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("驾驶证(正面)证照有误", "")
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.driverFace.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadDriverFace(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.driverFaceUrl;
            this.editDriverForm.driverFace.picUrl = res.drivers.driverFaceUrl;
            this.tableData[this.currentRow].DriverLicenseType = res.drivers
              .vehicle_type
              ? res.drivers.vehicle_type.substring(0, 2)
              : "";
            this.tableData[this.currentRow].DriverLicenseDateEnd = res.drivers
              .end_date
              ? this.insertStr(res.drivers.end_date, 4, "-", 7, "-")
              : "";
            this.tableData[this.currentRow].DriverLicenseDateStart = res.drivers
              .start_date
              ? this.insertStr(res.drivers.start_date, 4, "-", 7, "-")
              : "";
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("驾驶证(正面)证照有误", "")
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.driverFace.dialog();
            this.submitClick();
          });
      }
    },
    //上传驾驶证反面
    uploadDriverBack(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.driverBack.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("驾驶证(反面)证照有误", "")
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.driverBack.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadDriverBack(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers.driverBackUrl;
            this.editDriverForm.driverBack.picUrl = res.drivers.driverBackUrl;
            this.tableData[this.currentRow].DriverLicenseArchives = res.drivers
              .archive_no
              ? res.drivers.archive_no
              : "";
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("驾驶证(反面)证照有误", "")
              : "";
          })
          .catch(() => {
            // this.deleteImg(field)
          })
          .finally(() => {
            this.$refs.driverBack.dialog();
            this.submitClick();
          });
      }
    },
    //上传道路运输从业资格证
    uploadConveyLicence(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.RTQCertificateURL.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("道路运输从业资格证证照有误", "")
              : "";
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.RTQCertificateURL.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadConveyLicence(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers;
            this.editDriverForm.RTQCertificateURL.picUrl = res.drivers;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("道路运输从业资格证证照有误", "")
              : "";
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.RTQCertificateURL.dialog();
          });
      }
    },
    //上传银行卡
    uploadLegalPersonBankCard(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.legalPersonBankCard.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("银行卡证照有误", "")
              : "";
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.legalPersonBankCard.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadLegalPersonBankCard(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            if (res.enterPrise) {
              field.picUrl = res.enterPrise.ImgUrl;
              this.editDriverForm.legalPersonBankCard.picUrl =
                res.enterPrise.ImgUrl;
              this.tableData[this.currentRow].BankCardNo = res.enterPrise
                .card_num
                ? res.enterPrise.card_num
                : "";
            }
            this.tableData[this.currentRow].ErrorMsg = this.tableData[
              this.currentRow
            ].ErrorMsg
              ? this.tableData[this.currentRow].ErrorMsg.replace(
                "、",
                ""
              ).replace("银行卡证照有误", "")
              : "";
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.legalPersonBankCard.dialog();
          });
      }
    },
    //上传人员照片
    uploaDriverPhoto(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.DriverPhotoURL.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.uploaDriverPhoto.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploaDriverPhoto(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers;
            this.editDriverForm.DriverPhotoURL.picUrl = res.drivers;
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.uploaDriverPhoto.dialog();
          });
      }
    },
    //上传手持身份证
    uploadHandIdCard(field, needOCR) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      if (!needOCR) {
        uploadImg(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.data;
            this.editDriverForm.DriverIDCardPhoto.picUrl = res.data;
            this.tableData[this.currentRow].flag = true;
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.uploadHandIdCard.dialog();
            this.submitClick();
          });
      } else if (needOCR) {
        uploadHandIdCard(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.drivers;
            this.editDriverForm.DriverIDCardPhoto.picUrl = res.drivers;
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.uploadHandIdCard.dialog();
          });
      }
    },
    //表格item编辑保存
    confirmEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //把保存的值放到表格里面去
          this.tableData[this.currentRow].DriverCardOnURL = this.editDriverForm
            .idFace.picUrl
            ? this.editDriverForm.idFace.picUrl
            : null;
          this.tableData[this.currentRow].DriverCardBackURL = this
            .editDriverForm.idBack.picUrl
            ? this.editDriverForm.idBack.picUrl
            : null;
          this.tableData[this.currentRow].DriverLicenseOnURL = this
            .editDriverForm.driverFace.picUrl
            ? this.editDriverForm.driverFace.picUrl
            : null;
          this.tableData[this.currentRow].DriverLicenseBackURL = this
            .editDriverForm.driverBack.picUrl
            ? this.editDriverForm.driverBack.picUrl
            : null;
          this.tableData[this.currentRow].RTQCertificateURL = this
            .editDriverForm.RTQCertificateURL.picUrl
            ? this.editDriverForm.RTQCertificateURL.picUrl
            : null;
          this.tableData[this.currentRow].DriverPhotoURL = this.editDriverForm
            .DriverPhotoURL.picUrl
            ? this.editDriverForm.DriverPhotoURL.picUrl
            : null;
          this.tableData[this.currentRow].DriverIDCardPhoto = this
            .editDriverForm.DriverIDCardPhoto.picUrl
            ? this.editDriverForm.DriverIDCardPhoto.picUrl
            : null;
          this.tableData[this.currentRow].BankCardURL = this.editDriverForm
            .legalPersonBankCard.picUrl
            ? this.editDriverForm.legalPersonBankCard.picUrl
            : null;
          this.flag.editDriverPhoto = false;
        }
      });
    },
    //保存
    save(typeBtn, isQuoteTc) {
      //没有点过同步图片的不让提交
      if (!this.flag.clickSyncImgUrl && typeBtn == "submit") {
        if (isQuoteTc) {
          if (this.batchListAttribute != "1") {
            this.$message.warning("请点击“同步图片”后再进行提交资料");
            return;
          }
        } else {
          this.$message.warning("请点击“同步图片”后再进行提交资料");
          return;
        }
      }
      this.loading = true;
      let params = {
        baseInfo: [],
        confirmInfo: [],
        type: typeBtn == "submit" ? 1 : 0,
        importType: 1, //0普通导入  1网络货运导入
        IsQuoteTc: isQuoteTc, //是否引用运力池资料
      };
      let faillBaseInfo = []; //错误司机导出

      // 只有提交资料需要验证三要素
      if (typeBtn == "submit") {
        let count = 0; //请求了多少次
        let failList = []; //未通过二要素校验的列表
        this.tableData.forEach((item, index) => {
          //批量校验三要素
          realname({
            name: item.DriverName,
            phone: item.TelPhone,
            idCard: item.DriverIDCard,
          })
            .then((res) => {
              if (!res.data.IsOk) {
                failList.push(index + 1);
              }
            })
            .finally(() => {
              count = count + 1;
              //说明校验完毕
              if (count === this.tableData.length) {
                //判断是否全部通过
                //全部通过
                if (failList.length === 0) {
                  //二要素通过校验
                  for (let i in this.tableData) {
                    let item = this.tableData[i];
                    if (typeBtn == "submit") {
                      if (item.IsInTCName == "不存在") {
                        if (item.DriverCardOnURL == null) {
                          // this.$message.error(
                          //   `请上传第${Number(i) + 1}行中的身份证(正面)`
                          // );
                          //this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未上传身份证(正面)",
                          });
                          continue;
                        }
                        if (item.DriverCardBackURL == null) {
                          // this.$message.error(
                          //   `请上传第${Number(i) + 1}行中的身份证(反面)`
                          // );
                          //this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未上传身份证(反面)",
                          });
                          continue;
                        }
                        if (
                          item.DriverLicenseOnURL == null &&
                          this.driverIdentityType == "1"
                        ) {
                          // this.$message.error(
                          //   `请上传第${Number(i) + 1}行中的驾驶证(正页)`
                          // );
                          // this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未上传驾驶证(正页)",
                          });
                          continue;
                        }
                        if (
                          item.DriverLicenseBackURL == null &&
                          this.driverIdentityType == "1"
                        ) {
                          // this.$message.error(
                          //   `请上传第${Number(i) + 1}行中的驾驶证(副页)`
                          // );
                          // this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号码
                            ErrorMsg: "未上传驾驶证(副页)",
                          });
                          continue;
                        }
                        if (!item.DriverIDCard) {
                          // this.$message.error(
                          //   `请输入第${Number(i) + 1}行中的身份证号`
                          // );
                          // this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未填写身份证号",
                          });
                          continue;
                        }
                        if (
                          !item.DriverLicenseType &&
                          this.driverIdentityType == "1"
                        ) {
                          // this.$message.error(
                          //   `请选择第${Number(i) + 1}行中的准驾类型`
                          // );
                          // this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未填写准驾类型",
                          });
                          continue;
                        }
                        if (
                          !item.DriverLicenseDateStart &&
                          this.driverIdentityType == "1"
                        ) {
                          // this.$message.error(
                          //   `请选择第${Number(i) + 1}行中的驾驶证起始日期`
                          // );
                          // this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未填写驾驶证起始日期",
                          });
                          continue;
                        }
                        if (
                          !item.DriverLicenseDateEnd &&
                          this.driverIdentityType == "1"
                        ) {
                          // this.$message.error(
                          //   `请选择第${Number(i) + 1}行中的驾驶证有效期`
                          // );
                          // this.loading = false;
                          faillBaseInfo.push({
                            DriverName: item.DriverName, //司机姓名
                            TelPhone: item.TelPhone, //联系方式
                            DriverIDCard: item.DriverIDCard, //身份证号
                            VehicleCarNumber: item.VehicleCarNumber, //车牌号
                            ErrorMsg: "未填写驾驶证有效期",
                          });
                          continue;
                        }
                      }
                      if (item.ErrorMsg) {
                        // this.$message.error(
                        //   `导入信息有错误，请检查修改后再提交！`
                        // );
                        // this.loading = false;
                        faillBaseInfo.push({
                          DriverName: item.DriverName, //司机姓名
                          TelPhone: item.TelPhone, //联系方式
                          DriverIDCard: item.DriverIDCard, //身份证号
                          VehicleCarNumber: item.VehicleCarNumber, //车牌号
                          ErrorMsg: item.ErrorMsg,
                        });
                        continue;
                      }
                    }

                    params.baseInfo.push({
                      UserId: item.UserID ? item.UserID : 0,
                      DriverName: item.DriverName, //司机姓名
                      DriverSex:
                        item.DriverSex == "男" || item.DriverSex == 0
                          ? 0
                          : item.DriverSex == "女" || item.DriverSex == 1
                            ? 1
                            : 0, //性别
                      DriverIDCard: item.DriverIDCard, //身份证号
                      DriverAttribution: item.DriverAttribution, //司机归属
                      DriverLicenseType: item.DriverLicenseType, //准驾车型
                      DriverLicenseArchives: item.DriverLicenseArchives, //驾驶证档案编号
                      DriverLicense: "", //机动车驾驶证号
                      DriverLicenseDateStart: item.DriverLicenseDateStart, //驾驶证有效起始日期
                      DriverLicenseDateEnd: item.DriverLicenseDateEnd, //驾驶证有效结束日期
                      DriverLicesenAuthority: item.DriverLicesenAuthority, //驾驶证发证机关
                      DriverIDCardStartDate: item.DriverIDCardStartDate, //身份证有效起始日期
                      DriverIDCardEndDate: item.DriverIDCardEndDate, //身份证有效结束日期
                      DriverIDCardDate: item.DriverIDCardEndDate, //驾驶员身份证有效日期
                      RTQCertificateDate: item.RTQCertificateDate, //道路运输从业资格证有效期限
                      RTQCertificate: item.RTQCertificate, //道路运输从业资格证
                      Status: 0, //司机状态
                      Remark: item.ErrorMsg, //错误原因
                      TelPhone: item.TelPhone, //联系方式
                      Mechanism: "运服通平台", //运营机构
                      VehicleCarNumber: item.VehicleCarNumber,
                      BankCardNo: item.BankCardNo,
                      Address: item.address,
                      IsInTCName: item.IsInTCName,
                      SerialNum: Number(i) + 1,
                      Address: item.Address,
                      Birthday: item.Birthday,
                      Ethnic: item.Ethnic,
                      IdentityType: this.driverIdentityType,
                    });
                    params.confirmInfo.push({
                      UserId: 0,
                      DriverCardOnURL: item.DriverCardOnURL, //驾驶员身份证（正面）
                      DriverCardBackURL: item.DriverCardBackURL, //驾驶员身份证（反面）
                      DriverLicenseOnURL: item.DriverLicenseOnURL, //机动车驾驶证（正页)
                      DriverLicenseBackURL: item.DriverLicenseBackURL, //机动车驾驶证（副页)
                      RTQCertificateURL: item.RTQCertificateURL, //道路运输从业资格证
                      DocURL: "", //附件
                      DriverPhotoURL: item.DriverPhotoURL, //司机照片
                      DriverIDCardPhoto: item.DriverIDCardPhoto, //司机手持身份证照片
                      BankCardURL: item.BankCardURL,
                    });
                  }
                  params.baseInfo = params.baseInfo.reverse();
                  params.confirmInfo = params.confirmInfo.reverse();
                  saveDraftDriver(params)
                    .then((res) => {
                      // 查看草稿箱司机信息
                      this.driversDraftInfo();
                      this.flag.isBatch = false;
                    })
                    .finally(() => {
                      if (faillBaseInfo.length) {
                        ExportFailDriver(faillBaseInfo).then((res) => {
                          window.location.href = res.data;
                        });
                        let arr = this.tableData.filter((item) => !faillBaseInfo.some((it) => ((item.DriverIDCard === it.DriverIDCard) && (item.TelPhone === it.TelPhone))))
                        this.tableData = JSON.parse(JSON.stringify(arr))
                      }
                      this.loading = false;
                    });
                } else {
                  this.$confirm(
                    `序号为${failList.sort().join()}的司机二要素校验不通过!`,
                    "提示",
                    {
                      confirmButtonText: "确定",
                      showCancelButton: false,
                      type: "warning",
                    }
                  ).then(() => {
                    this.loading = false;
                  });
                }
              }
            });
        });
      } else {
        //暂存不用校验三要素
        for (let i in this.tableData) {
          let item = this.tableData[i];
          if (typeBtn == "submit") {
            if (item.IsInTCName == "不存在") {
              if (item.DriverCardOnURL == null) {
                this.$message.error(
                  `请上传第${Number(i) + 1}行中的身份证(正面)`
                );
                this.loading = false;
                return;
              }
              if (item.DriverCardBackURL == null) {
                this.$message.error(
                  `请上传第${Number(i) + 1}行中的身份证(反面)`
                );
                this.loading = false;
                return;
              }
              if (
                item.DriverLicenseOnURL == null &&
                this.driverIdentityType == "1"
              ) {
                this.$message.error(
                  `请上传第${Number(i) + 1}行中的驾驶证(正页)`
                );
                this.loading = false;
                return;
              }
              if (
                item.DriverLicenseBackURL == null &&
                this.driverIdentityType == "1"
              ) {
                this.$message.error(
                  `请上传第${Number(i) + 1}行中的驾驶证(副页)`
                );
                this.loading = false;
                return;
              }
            }
            // if (item.ErrorMsg) {
            //   this.$message.error(`导入信息有错误，请检查修改后再提交！`);
            //   this.loading = false;
            //   return;
            // }
          }
          // if (item.ErrorMsg) {
          //   this.$message.error(`导入信息有错误，请检查修改后再提交！`);
          //   this.loading = false;
          //   return;
          // }
          params.baseInfo.push({
            UserId: item.UserID ? item.UserID : 0,
            DriverName: item.DriverName, //司机姓名
            // DriverSex: (item.DriverSex == '男' || item.DriverSex == 0) ? 0 : ((item.DriverSex == '女' || item.DriverSex == 1) ? 1 : 2), //性别
            DriverSex: item.DriverSexCode, //性别
            DriverIDCard: item.DriverIDCard, //身份证号
            DriverAttribution: item.DriverAttribution, //司机归属
            DriverLicenseType: item.DriverLicenseType, //准驾车型
            DriverLicenseArchives: item.DriverLicenseArchives, //驾驶证档案编号
            DriverLicense: "", //机动车驾驶证号
            DriverLicenseDateStart: item.DriverLicenseDateStart, //驾驶证有效起始日期
            DriverLicenseDateEnd: item.DriverLicenseDateEnd, //驾驶证有效结束日期
            DriverLicesenAuthority: item.DriverLicesenAuthority, //驾驶证发证机关
            DriverIDCardStartDate: item.DriverIDCardStartDate, //身份证有效起始日期
            DriverIDCardEndDate: item.DriverIDCardEndDate, //身份证有效结束日期
            DriverIDCardDate: item.DriverIDCardEndDate, //驾驶员身份证有效日期
            RTQCertificateDate: item.RTQCertificateDate, //道路运输从业资格证有效期限
            RTQCertificate: item.RTQCertificate, //道路运输从业资格证
            Status: 0, //司机状态
            Remark: item.ErrorMsg ? item.ErrorMsg : item.Remark, //错误原因
            TelPhone: item.TelPhone, //联系方式
            Mechanism: "运服通平台", //运营机构
            VehicleCarNumber: item.VehicleCarNumber,
            BankCardNo: item.BankCardNo,
            Address: item.address,
            IsInTCName: item.IsInTCName,
            SerialNum: Number(i) + 1,
            Address: item.Address,
            Birthday: item.Birthday,
            Ethnic: item.Ethnic,
            IdentityType: this.driverIdentityType,
          });
          params.confirmInfo.push({
            UserId: 0,
            DriverCardOnURL: item.DriverCardOnURL, //驾驶员身份证（正面）
            DriverCardBackURL: item.DriverCardBackURL, //驾驶员身份证（反面）
            DriverLicenseOnURL: item.DriverLicenseOnURL, //机动车驾驶证（正页)
            DriverLicenseBackURL: item.DriverLicenseBackURL, //机动车驾驶证（副页)
            RTQCertificateURL: item.RTQCertificateURL, //道路运输从业资格证
            DocURL: "", //附件
            DriverPhotoURL: item.DriverPhotoURL, //司机照片
            DriverIDCardPhoto: item.DriverIDCardPhoto, //司机手持身份证照片
            BankCardURL: item.BankCardURL,
          });
        }
        params.baseInfo = params.baseInfo.reverse();
        params.confirmInfo = params.confirmInfo.reverse();
        saveDraftDriver(params)
          .then((res) => {
            // 查看草稿箱司机信息
            this.driversDraftInfo();
            this.flag.isBatch = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    //预览图片
    previewImg(url) {
      if (url) {
        this.currentImgUrl = url;
        this.flag.showImg = !this.flag.showImg;
      } else {
        this.$message.error("请先上传相关照片");
      }
    },
    //删除图片
    deleteImg(field) {
      this.tableData.forEach((item) => {
        if (item.DriverCardOnURL == field.picUrl) {
          item.DriverCardOnURL = null;
        } else if (item.DriverCardBackURL == field.picUrl) {
          item.DriverCardBackURL = null;
        } else if (item.DriverLicenseOnURL == field.picUrl) {
          item.DriverLicenseOnURL = null;
        } else if (item.DriverLicenseBackURL == field.picUrl) {
          item.DriverLicenseBackURL = null;
        } else if (item.RTQCertificateURL == field.picUrl) {
          item.RTQCertificateURL = null;
        } else if (item.BankCardURL == field.picUrl) {
          item.BankCardURL = null;
        } else if (item.DriverIDCardPhoto == field.picUrl) {
          item.DriverIDCardPhoto = null;
        } else if (item.DriverPhotoURL == field.picUrl) {
          item.DriverPhotoURL = null;
        }
      });
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //删除批量导入里得某一个
    delListItem(index) {
      this.$confirm("是否删除该照片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.bulkImportList.splice(index, 1);
        })
        .catch(() => { });
    },

    // 设置被删除行的样式
    setDeletedRow({ row, rowIndex }) {
      if (row.IsTrouble) {
        return "deletedRowStyle";
      }
    },

    //计算table高度(动态设置table高度)
    getTableHeight() {
      let tableH = 250; // 页面其余元素的高度(估值)
      this.$nextTick(() => {
        let formH = this.$refs.form.$el.offsetHeight; // form 查询表单的高度
        this.tableHeight =
          window.innerHeight - tableH - formH <= 150
            ? 150
            : window.innerHeight - tableH - formH;
      });
    },
  },
  mounted() {
    //挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  watch: {
    "flag.isBatch"(val) {
      if (val == false) {
        this.tableData = [];
        this.IsMyTC = "全部";
        this.batchListAttribute = "0";
        this.$refs.multipleTable.clearSelection();
        this.driversDraftInfo();
        this.loading = false;
        this.flag.clickSyncImgUrl = false;
      }
    },
    "flag.editDriverPhoto"(val) {
      if (!val) {
        //每次关闭编辑的dialog时清空里边的数据
        this.editDriverForm.idFace.picUrl = "";
        this.editDriverForm.idBack.picUrl = "";
        this.editDriverForm.driverFace.picUrl = "";
        this.editDriverForm.driverBack.picUrl = "";
        this.editDriverForm.RTQCertificateURL.picUrl = "";
        this.editDriverForm.legalPersonBankCard.picUrl = "";
        this.editDriverForm.DriverPhotoURL.picUrl = "";
        this.editDriverForm.DriverIDCardPhoto.picUrl = "";
        //清空图片上传组件的状态
        this.editDriverForm.idFace.loading = false;
        this.editDriverForm.idFace.uploaded = false;
        this.editDriverForm.idBack.loading = false;
        this.editDriverForm.idBack.uploaded = false;
        this.editDriverForm.driverFace.loading = false;
        this.editDriverForm.driverFace.uploaded = false;
        this.editDriverForm.driverBack.loading = false;
        this.editDriverForm.driverBack.uploaded = false;
        this.editDriverForm.RTQCertificateURL.loading = false;
        this.editDriverForm.RTQCertificateURL.uploaded = false;
        this.editDriverForm.legalPersonBankCard.loading = false;
        this.editDriverForm.legalPersonBankCard.uploaded = false;
        this.editDriverForm.DriverPhotoURL.loading = false;
        this.editDriverForm.DriverPhotoURL.uploaded = false;
        this.editDriverForm.DriverIDCardPhoto.loading = false;
        this.editDriverForm.DriverIDCardPhoto.uploaded = false;
      } else {
        //每次打开的时候需要填充编辑dialog的数据
        this.editDriverForm.idFace.picUrl =
          this.tableData[this.currentRow].DriverCardOnURL;
        this.editDriverForm.idBack.picUrl =
          this.tableData[this.currentRow].DriverCardBackURL;
        this.editDriverForm.driverFace.picUrl =
          this.tableData[this.currentRow].DriverLicenseOnURL;
        this.editDriverForm.driverBack.picUrl =
          this.tableData[this.currentRow].DriverLicenseBackURL;
        this.editDriverForm.RTQCertificateURL.picUrl =
          this.tableData[this.currentRow].RTQCertificateURL;
        this.editDriverForm.legalPersonBankCard.picUrl =
          this.tableData[this.currentRow].BankCardURL;
        this.editDriverForm.DriverPhotoURL.picUrl =
          this.tableData[this.currentRow].DriverPhotoURL;
        this.editDriverForm.DriverIDCardPhoto.picUrl =
          this.tableData[this.currentRow].DriverIDCardPhoto;
      }
    },
  },
  components: {
    UploadImg,
  },
};
</script>

<style lang="scss">
.bulkImportPage {
  .vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell {
    max-height: 100px;
  }

  .vxe-table--render-default .vxe-body--column.col--ellipsis,
  .vxe-table--render-default.vxe-editable .vxe-body--column {
    height: 100px;
  }

  .isBatch {
    .el-dialog__body {
      padding: 0px 20px 30px 20px;
    }
  }
}

.deletedRowStyle {
  background-color: #ebc0c0 !important;
}
</style>

<style scoped lang="scss">
@import "../../../../assets/style/variable.scss";

.bulkImportPage {
  // width: 99%;

  .tableWrap {
    border-width: 10px;
    background: white;
    border-style: solid;
    border-color: white;
  }

  .top_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .funcs {
    margin-bottom: 10px;
  }

  .el-table {
    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .driverInfo {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .hint {
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;

        span {
          color: #ff2323;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .download {
        color: $primary;
        cursor: pointer;
      }
    }
  }

  .driverPhoto {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .body {
      .uploadArea {
        .upload {
          width: 126px;
          height: 84px;
          margin: 0 0 10px 0;
          cursor: pointer;
          background-image: url("../../../../assets/image/upload.png");
          background-size: 100%;
        }

        .bulkImportList {
          max-height: 400px;
          overflow-y: scroll;
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;

          .item {
            position: relative;
            margin: 0 10px 10px 0;
            display: flex;
            flex-direction: column;

            img {
              width: 126px;
              height: 84px;
              border-radius: 10px;
            }

            span {
              padding-top: 5px;
              font-size: 12px;
              color: $textGray2;
            }

            i {
              position: absolute;
              top: 0px;
              right: 0px;
              color: rgb(219, 9, 9);
              cursor: pointer;
              padding: 10px;
            }
          }
        }
      }

      .hint {
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;

        span {
          color: #ff2323;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .editDriverForm {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .upBox {
        text-align: center;

        .upText {
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0;
          color: #333333;
        }
      }

      .el-upload__tip {
        color: $textGray2;
      }
    }
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>
